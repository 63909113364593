import { Button, Text } from '@village/ui';
import { rem } from 'polished';
import ReactDatePicker from 'react-datepicker';
import styled from 'styled-components';

const Label = styled(Text).attrs(() => ({ type: 'caption1', tag: 'label' }))`
    display: block;
    margin-bottom: ${rem(6)};
    color: ${({ theme }) => theme.vui.colors.text.grayDefault};
`;

const DatePicker = styled(ReactDatePicker)`
    & #root-portal {
        z-index: 1000 !important;
    }
    button {
        width: ${rem(196)} !important;
    }
    font-family: Apercu, Arial, Helvetica, sans-serif;
`;

const MobileDatePickerButton = styled(Button)`
    min-width: ${rem(196)} !important;
    width: auto;
`;

export { DatePicker, Label, MobileDatePickerButton };
