import styled from 'styled-components';
import { Theme } from '@material-ui/core';

import { Props } from '.';

const getBackgroundColor = (status: Props['status'], theme: Theme) => {
    switch (status) {
        case 'warning':
            return theme.vui.colors.surface.attention;
        case 'critical':
            return theme.vui.colors.surface.critical;
        case 'detail':
            return theme.vui.colors.background.gray;
        case 'visit_type':
            return theme.vui.colors.surface.blue;
        default:
            return theme.vui.colors.background.blue;
    }
};

const getIconColor = (status: Props['status'], theme: Theme) => {
    switch (status) {
        case 'critical':
            return theme.vui.colors.icon.critical;
        default:
            return undefined;
    }
};

const Container = styled.div<{ $status: Props['status']; $hasTitleAndContent: boolean }>`
    display: flex;
    gap: 0.5rem;
    align-items: ${({ $hasTitleAndContent }) => ($hasTitleAndContent ? 'unset' : 'center')};
    padding: 1rem;
    border-radius: 4px;
    background: ${({ $status, theme }) => getBackgroundColor($status, theme)};

    & svg {
        color: ${({ $status, theme }) => getIconColor($status, theme)};
    }
`;

const Content = styled.div<{ $hasTitle: boolean; $variant: Props['variant'] }>`
    width: 100%;
    margin-top: ${({ $hasTitle, $variant }) => ($hasTitle && $variant !== 'small' ? '0.5rem' : '0')};
`;

const ContentWrapper = styled.div`
    width: 100%;
`;

export { Container, Content, ContentWrapper };
